


















import { Component, Vue } from 'vue-property-decorator';
import DadosMarca from '@/components/dados/DadosMarca.vue';
import DadosModelo from '@/components/dados/DadosModelo.vue';
import DadosAno from '@/components/dados/DadosAno.vue';
import DadosProsseguir from '@/components/dados/DadosProsseguir.vue';

@Component({
  components: {
    DadosAno,
    DadosModelo,
    DadosMarca,
    DadosProsseguir,
  },
})
export default class DadosVeiculo extends Vue {
  public valid = false;

  get origemFipe() {
    return this.$store.state.origem_fipe;
  }

  public mounted() {
    this.$store.commit('SET_SIDEBAR_SHOWING', false);
    this.$store.commit('SET_SIDEBAR_ENABLED', false);

    window.funnelytics.functions.step();
  }
}
