import axios from 'axios';

export default class Indicadores {
  public static get(cod: string): Promise<IndicadorResponse> {
    return Indicadores.instance
      .get<IndicadorResponse>(`indicadores.php`, { params: { ref: cod } })
      .then((response) => response.data);
  }

  public static createDeal(data: {}): Promise<GenericResponse> {
    return Indicadores.instance
      .post<GenericResponse>(`moskit/deal.php`, data)
      .then((response) => response.data);
  }

  private static instance = axios.create({
    baseURL: 'https://dashboard.parceirosproteauto.com.br/api/v1/',
  });
}
