

















import { Vue, Component, Prop } from 'vue-property-decorator';
import ChevronDoubleRightIcon from 'vue-material-design-icons/ChevronDoubleRight.vue';
import RdStation from '@/plugins/api/RdStation';
import Simulador from '@/plugins/api/Simulador';
import Indicadores from '@/plugins/api/Indicadores';

@Component({
  components: { ChevronDoubleRightIcon },
})
export default class DadosProsseguir extends Vue {
  @Prop({ default: false }) public readonly valid!: boolean;

  get cotacao() {
    return this.$store.state.cotacao;
  }

  get loadingFipe() {
    return this.$store.state.loading_fipe;
  }

  get textButton() {
    if (this.cotacao.origem_fipe) {
      return 'Calcular Valor da Proteção';
    } else if (this.$route.name === 'dados') {
      return 'Ver o valor da Proteção';
    } else if (this.$route.name === 'cliente') {
      return 'Continuar';
    } else if (this.$route.name === 'veiculo') {
      return 'Ver o valor da Proteção';
    }
  }

  get nextRoute() {
    if (this.$route.name === 'cliente') {
      return 'veiculo';
    } else {
      return 'detalhes';
    }
  }

  get nextUrl() {
    if (this.cotacao.valor_fipe > 100000) {
      return 'acima100';
    }
    return 'abaixo100';
  }

  get nextQuery() {
    const query = {} as { ref: string };
    if (this.cotacao.cod_indicador !== 0) {
      query.ref = this.cotacao.cod_indicador as string;
    }
    return query;
  }

  get email() {
    return this.$store.getters.get_email;
  }

  get dddTelefone() {
    return `${this.cotacao.ddd}${this.cotacao.telefone}`;
  }

  get dados(): { [key: string]: any } {
    return {
      nome: this.cotacao.nome,
      email: this.email,
      ddd: this.cotacao.ddd,
      telefone: this.cotacao.telefone.replace(/\D/g, ''),
      placa: this.cotacao.placa,
      marca: this.cotacao.marca,
      modelo: this.cotacao.modelo,
      ano: this.cotacao.ano,
      combustivel: this.cotacao.combustivel,
      fipe_codigo: this.cotacao.fipe_codigo,
      valor_fipe: this.cotacao.valor_fipe,
      cobertura: this.cotacao.cobertura,
      valor_cobertura: this.cotacao.valor_cobertura,
      valor_adesao: this.cotacao.valor_adesao,
      valor_plano: this.$store.getters.valor_plano,
      valor_mensalidade: this.$store.getters.valor_mensalidade,
      indicador: this.$store.state.simulacao.indicador,
      cod_indicador: this.cotacao.cod_indicador,
      vendedor: this.cotacao.vendedor,
    };
  }

  public async onClick() {
    if ((this.$parent as VuetifyForm).validate()) {
      this.$router.push({
        name: this.nextRoute,
        params: { arg: this.nextUrl },
        query: this.nextQuery,
      });

      const dados = this.dados;
      let data;
      if (this.$store.state.cotacao.id === 0) {
        data = await Simulador.create(dados);
        this.$store.commit('SET_COTACAO_ID', data.id);
        this.$store.commit('SET_PROTOCOLO', data.protocolo);
      } else {
        dados.id = this.$store.state.cotacao.id;
        data = Simulador.update(dados);
      }

      let identificador = 'Simulador site';

      if (this.cotacao.cod_indicador !== 0) {
        this.conversaoIndicador(data);
        identificador += ' Link de venda';
      }

      if (this.cotacao.vendedor === undefined) {
        this.conversaoPrincipal(identificador, data);

        if (this.cotacao.valor_fipe > 100000) {
          this.conversaoCaminhao();
        }
      }

      window.fbq('track', 'Lead');
      window.fbq('track', 'AddToCart', {
        value: this.$store.getters.valor_mensalidade,
        currency: 'BRL',
      });
    }
  }

  public conversaoIndicador(data: any) {
    Indicadores.createDeal({
      ...this.dados,
      id_proposta: data.id,
    }).then();
    RdStation.conversion({
      email: this.email,
      identificador: `Indicador APP`,
      codReferencia: this.cotacao.cod_indicador,
      nomeIndicador: this.$store.state.simulacao.indicador,
    });
  }

  public conversaoCaminhao() {
    RdStation.conversion({
      email: this.email,
      identificador: 'Caminhão Acima de 100mil',
    }).then();
  }

  public conversaoPrincipal(identificador: string, data: any) {
    RdStation.conversion({
      'nome': this.cotacao.nome,
      'email': this.email,
      'identificador': identificador,
      'telefone': this.dddTelefone,
      'celular': this.dddTelefone,

      'Tipo de veículo': 'Caminhão',
      'Marca': this.cotacao.marca,
      'Veículo': this.cotacao.modelo,
      'Modelo': this.$store.getters.veiculo_ano,
      'Preço do Veículo': this.cotacao.valor_fipe,
      'Mensalidade teto': this.$store.getters.valor_mensalidade,

      'Link proposta': `https://prote.me/${data.protocolo}`,
      'id_proposta': data.id,
    }).then();
  }
}
